@import "../../../assets/main.scss";

.featured {
  background-color: $darkgrey;
  height: 45rem;

  @include mobile {
    height: auto;
    padding: 1rem;
  }

  .featured-wrapper {
    padding-top: 8rem;
    padding-bottom: 8rem;
    display: flex;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
    text-align: center;
    gap: 1rem;

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .featured-item {
      width: 100%;
      height: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1.8rem;
      background-color: #18181a;
      padding-top: 7rem;

      @include mobile {
        height: 25rem;
        gap: 0;
      }

      span {
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        letter-spacing: 0.1rem;
        font-size: 0.9rem;

        @include mobile {
          padding: 1rem;
        }
      }

      svg {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.8rem;
        color: white;
        font-weight: 600;

        @include mobile {
          padding: 1rem;
        }
      }

      button {
        background-color: transparent;
        border: 2px solid white;
        color: white;
        font-weight: 600;
        padding: 0.7rem 2.9rem;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }

    .featured-item-img {
      background-image: url(../../../static/opatija-6.webp);
      box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      -webkit-box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      -moz-box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      opacity: 1;
      background-position: center;
      width: 100%;
      height: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1.8rem;
      background-color: $dark;
      padding-top: 7rem;
      overflow: hidden;

      cursor: pointer;

      span {
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 0.9rem;
      }

      svg {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.6rem;
        color: white;
      }
    }
  }
}
