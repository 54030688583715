@import "../../../assets/main.scss";

.section-3 {
  background-color: $darkgrey;

  .section-3-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .section-3-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      // padding-bottom: 5rem;

      @include mobile {
        padding: 3rem 0rem;
      }

      h3 {
        color: #ffffff;
        font-weight: 600;
        font-size: 2.5rem;
      }

      p {
        color: #fff;
        font-size: 1rem;
        line-height: 2rem;
        width: 70%;
        @include mobile {
          padding: 1rem;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0.5rem;
        width: 100%;

        span {
          display: flex;
          color: red;
          font-size: 0.8rem;
          align-items: start !important;
          text-align: start !important;
        }

        @include mobile {
          padding: 1rem;
        }

        input {
          background-color: transparent;
          border: 1px solid gray;
          padding: 1rem;
          color: white;
          width: 70%;
          font-size: 1rem;
          font-weight: 500;

          @include mobile {
            width: 100%;
          }

          &::placeholder {
            font-size: 1rem;
          }

          &::content {
            color: white;
          }
        }

        textarea {
          background-color: transparent;
          border: 1px solid gray;
          padding: 1rem;
          width: 70%;
          color: white;
          font-size: 1rem;
          font-weight: 500;

          @include mobile {
            width: 100%;
          }

          &::placeholder {
            font-size: 1rem;
          }

          &::content {
            color: white;
          }
        }

        button {
          padding: 0.8rem 2rem;
          color: black;
          font-weight: 500;
          font-size: 0.9rem;
          border: none;
          letter-spacing: 0.08rem;
          cursor: pointer;
          text-transform: uppercase;

          &:hover {
            background-color: yellow;
          }
        }

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 1rem 0rem;

          .result {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 1rem;

            .result-email {
              width: 100%;
            }

            svg {
              font-size: 3rem;
              color: green;
            }
          }
        }
      }
    }

    .section-3-image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 50rem;
        object-fit: cover;
        filter: brightness(75%);

        @include mobile {
          width: 100%;
          height: 30rem;
        }
      }
    }
  }
}
