@import "../../../assets/main.scss";

.nav-wrap {
  background: transparent;
  padding: 1.5rem;
  position: absolute;
  @include rem(top, 42.88px);
  right: 0;
  left: 0;
  z-index: 999;
  @include mobile {
    padding: 0rem;
  }

  &.active {
    background: black;
    top: 0;
    position: fixed;
    @include mobile {
      padding: 0rem;
    }
  }

  &.not-homepage {
    position: sticky;
  }

  .container-nav {
    @include rem(max-width, 1360px);
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include rem(padding-left, 20px);
    @include rem(padding-right, 20px);

    @include mobile {
      max-width: none;
      @include rem(padding-left, 0px);
      @include rem(padding-right, 0px);
    }

    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      .nav-items {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
        font-weight: 500;
        color: white;

        @include mobile {
          display: none;
        }

        li {
          letter-spacing: 0.15rem;
          font-size: 0.8rem;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: chocolate;
          }
        }

        p {
          letter-spacing: 0.15rem;
          font-size: 1.3rem;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: chocolate;
          }
        }
      }

      .nav-items-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
        font-weight: 500;
        color: white;

        li {
          letter-spacing: 0.15rem;
          font-size: 0.8rem;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: chocolate;
          }
        }

        p {
          letter-spacing: 0.15rem;
          font-size: 1.3rem;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: chocolate;
          }
        }
      }
    }

    .nav-mobile {
      width: 100%;
      max-width: none;
      display: flex;
      flex-direction: column;

      .nav-wrap-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        @include mobile {
          padding: 1rem;
        }

        p {
          letter-spacing: 0.15rem;
          font-size: 1.3rem;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: 600;
          color: #ffffff;

          @include mobile {
            font-size: 1.1rem;
          }

          &:hover {
            color: chocolate;
          }
        }

        .hamburger-react {
          color: white;
        }
      }

      .menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        @include rem(top, 8px);

        z-index: -9999;
        width: 100%;
        height: 100vh;
        background-color: $black;

        .nav-items {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          color: white;
          padding: 6rem 0rem;

          li {
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1.5em;
            font-weight: 700;
            border-bottom: 0.01px solid #ffffff;
          }
        }

        .nav-items-icon {
          display: flex;
          justify-content: start;
          align-items: start;
          gap: 1rem;

          span {
            color: white;

            svg {
              font-size: 1.7rem;
            }
          }
        }
      }
    }
  }
}
