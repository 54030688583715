@import "../../../assets/main.scss";

.menu-section-4 {
  background-color: $darkgrey;
  height: auto;

  @include mobile {
    padding: 0rem;
  }

  .menu-wrapper__section-4 {
    display: flex;
    flex-direction: column;
    gap: 10rem;
    padding-top: 10rem;
    padding-bottom: 10rem;

    .menu__section-4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;

      .menu__section-4__text {
        h1 {
          font-size: 2.1rem;

          font-weight: 600;
          letter-spacing: 0.2rem;
          color: white;
        }

        p {
          padding-top: 1rem;
          font-size: 1rem;
          color: gray;
        }
      }

      .vl {
        padding-top: 1rem;
        border-left: 3px solid white;
        height: 2.5rem;
      }

      .tabs {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        @include mobile {
          display: flex;
          overflow-x: scroll;
          width: 20rem;
        }

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 4rem;

          @include mobile {
            display: flex;
            width: max-content;
          }
        }

        .vh {
          display: block;
          border-bottom: 3px solid white;
          width: auto;
        }

        .tab {
          font-size: 1rem;
          color: $heading;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .menu__section-4__drink {
        background-color: $dark;
        width: 100%;

        .menu-items-wine {
          width: 100%;
          height: auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 8rem;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: start;
          padding: 3rem;

          @include mobile {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 1rem;
          }

          @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .menu-items-wrapper:nth-child(1) {
            border: 1px solid rgb(88, 83, 83);
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            padding: 1rem;

            .text-items {
              display: flex;
              flex-direction: column;
              align-items: start;
              text-align: start;
              gap: 0.3rem;

              h2 {
                font-size: 1.5rem;
                color: white;
                text-transform: uppercase;
                letter-spacing: 0.5rem;
              }

              p {
                color: gray;
                font-size: 0.8rem;
              }
            }

            .collection-name {
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              gap: 1.5rem;

              span {
                color: $heading;
                font-size: 0.8rem;
              }
            }

            .items {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1.5rem;

              .item {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;

                span {
                  color: white;
                  font-weight: 600;

                  @include mobile {
                  }
                  span {
                    color: gray;
                    font-size: 0.8rem;
                    font-weight: normal;
                  }
                }

                span:nth-child(2) {
                  color: gray;
                  font-weight: normal;
                  font-size: 0.7rem;
                }
              }

              .item-price {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                align-content: flex-end;
                gap: 3rem;

                @include mobile {
                  display: flex;
                  justify-content: flex-end;
                  width: 100%;
                }

                span {
                  color: gray;
                  font-weight: 500;
                  min-width: max-content;
                }
              }
            }
          }
        }

        .menu-items-wrapper:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 1.6rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .collection-name {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1.5rem;

            span {
              color: $heading;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.4rem;

              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                color: gray;
                font-weight: normal;
                font-size: 0.7rem;
              }
            }

            .item-price {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              align-content: flex-end;
              gap: 3rem;
              min-width: max-content;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-wrapper {
          display: flex;
          flex-direction: column;
          gap: 1.6rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;

              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.7rem;
              }
            }

            .item-price {
              display: flex;
              align-items: center;
              min-width: max-content;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-wrapper:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          background-color: $darkgrey;
          padding: 1rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .collection-name {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1.5rem;

            span {
              color: $heading;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;

              span {
                color: white;
                font-weight: 600;

                span {
                  color: gray;
                  font-size: 0.9rem;
                }
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.7rem;
              }
            }

            .item-price {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              align-content: flex-end;
              gap: 3rem;
              min-width: max-content;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }
      }

      .menu-items-cocktails {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;

        .text-items {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 0.3rem;
          padding: 2rem 0rem;

          h2 {
            font-size: 1.5rem;
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.5rem;
          }

          p {
            color: gray;
            font-size: 0.8rem;
          }
        }

        .menu-items-wrapper-cocktails {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4rem;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 1rem;

          @include mobile {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 1rem;
          }
          @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .items {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            gap: 0.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;

              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.8rem;
              }
            }
            .item-price {
              min-width: max-content;

              span {
                color: gray;
                font-weight: 500;
              }
            }

            .item-price-wein {
              display: flex;
              align-items: center;
              gap: 1rem;
              min-width: max-content;

              span {
                color: gray;
                font-weight: 500;
              }
            }
            .item-price-collection {
              display: flex;
              align-items: center;
              gap: 1rem;

              span {
                color: gray;
                font-weight: 600;
              }
            }
          }
        }
      }

      .menu-items-biere {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8rem;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;
        padding: 3rem;

        @include mobile {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          padding: 1rem;
        }

        @include tablet {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .menu-items-wrapper-biere {
          border: 1px solid rgb(88, 83, 83);
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          padding: 1rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .collection-name {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1.5rem;

            span {
              color: $heading;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;

              span {
                color: white;
                font-weight: 600;

                @include mobile {
                }
                span {
                  color: gray;
                  font-size: 0.8rem;
                  font-weight: normal;
                }
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.7rem;
              }
            }

            .item-price {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              align-content: flex-end;
              gap: 3rem;
              min-width: max-content;

              @include mobile {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                min-width: max-content;
              }

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-wrapper-spirituosen {
          background-color: $black;
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          padding: 1rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .collection-name {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1.5rem;

            span {
              color: $heading;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;

              span {
                color: white;
                font-weight: 600;

                @include mobile {
                }
                span {
                  color: gray;
                  font-size: 0.8rem;
                  font-weight: normal;
                }
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.7rem;
              }
            }

            .item-price {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              align-content: flex-end;
              gap: 3rem;

              @include mobile {
                display: flex;
                justify-content: flex-end;
                width: 100%;
              }

              span {
                color: gray;
                font-weight: 500;
                min-width: max-content;
              }
            }
          }
        }
      }
    }
  }
}
