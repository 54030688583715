@import "../../assets/main.scss";

.section-privacy-1 {
    width: 100%;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../static/opatija-6.webp);
    background-position: center;
    box-shadow: 3px -1px 200px 50px rgb(0, 0, 0) inset;

    .container {
        .text-privacy-1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            span {
                font-size: 0.8rem;
                color: white;
                font-weight: 600;
                padding-bottom: 1rem;
                letter-spacing: 0.1rem;

                @include mobile {
                    font-size: 0.8rem;
                }
            }

            h1 {
                font-size: 2.5rem;
                color: white;
                font-weight: 600;

                @include mobile {
                    font-size: 2rem;
                }
            }
        }
    }
}

.section-privacy-2 {
    background-color: $darkgrey;

    .wrapper {
        padding: 6rem 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .items {
            display: flex;
            flex-direction: column;
            gap: 3rem;

            .item {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 0.5rem;

                h4 {
                    color: white;
                    font-size: 2rem;
                }

                span,
                a {
                    color: gray;
                    font-size: 0.9rem;
                }

                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        color: gray;
                    }
                }
            }
        }
    }
}
