@import "../../../assets/main.scss";

.featured2 {
  background-color: $darkgrey;
  height: auto;

  @include mobile {
    height: auto;
  }

  .container {
    @include rem(max-width, 1700px);
    width: 100%;
    margin: 0 auto;
    position: relative;

    .featured2-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .featured2-text {
        width: 50%;

        @include mobile {
          width: 100%;
        }

        h3 {
          font-size: 1.4rem;
          font-weight: normal;
          color: #c2a8a8;
          padding-bottom: 1.3rem;
        }

        p {
          color: #c2a8a8;
        }
      }
    }

    .featured2-items {
      display: flex;
      justify-content: space-between;
      padding-top: 10rem;
      padding-bottom: 5rem;
      gap: 10rem;

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        padding-bottom: 0rem;
        gap: 3rem;
      }

      .featured2-img {
        width: 50%;
        height: auto;

        @include mobile {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .featured2-text__items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 37rem;
        gap: 1.3rem;

        @include mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
        }

        span {
          text-transform: uppercase;
          color: white;
          font-weight: bold;
        }

        h3 {
          color: white;
        }

        p {
          line-height: 1.6rem;
          font-size: 1rem;
          color: #c2a8a8;
        }

        button {
          padding: 1rem 3rem;
          font-size: 0.9rem;
          text-transform: uppercase;
          cursor: pointer;
          border: none;
          font-weight: bolder;
          color: black;

          &:hover {
            background-color: chocolate;
            color: white;
          }
        }
      }
    }
  }
}