@import "../../../assets/main.scss";

.menu {
  background-color: $darkgrey;
  height: auto;
  @include mobile {
    padding: 1rem;
  }

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10rem;
    padding-top: 10rem;
    padding-bottom: 10rem;

    .menu__section-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;

      .menu__section-1__text {
        h1 {
          font-size: 2rem;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.2rem;
          color: white;
        }

        p {
          padding-top: 1rem;
          font-size: 1rem;
          color: gray;
        }
      }

      .vl {
        padding-top: 1rem;
        border-left: 3px solid white;
        height: 2.5rem;
      }

      .menu__section-1__food {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3rem;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;

        @include mobile {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
        }

        @include tablet {
          display: flex;
          flex-wrap: wrap;
        }

        .items {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1.5rem;

          @include mobile {
          }

          .item {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
            @include mobile {
              width: 70%;
            }

            span {
              color: white;
              font-weight: 600;
            }

            span:nth-child(2) {
              color: gray;
              font-weight: normal;
            }
          }

          .item-price {
            display: flex;
            align-items: center;

            span {
              color: gray;
              font-weight: 600;
              min-width: max-content;
            }
          }
        }
      }
    }
  }
}
