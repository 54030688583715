.link {
  text-decoration: none;
  color: inherit;
}

.container {
  @include rem(max-width, 1360px);
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include rem(padding-left, 20px);
  @include rem(padding-right, 20px);

  @include mobile {
    @include rem(padding-left, 15px);
    @include rem(padding-right, 15px);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b,
small,
.text_small {
  color: $black;
  // font-family: $fontsName;
  // font-family: 'Montserrat', sans-serif;
 // font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include rem(margin, 15px 0 10px);
  font-weight: 700;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-weight: 900;
  @include rem(font-size, 67px);

  @include mobile {
    @include rem(font-size, 55px);
  }
}

h2 {
  @include rem(font-size, 50px);

  @include mobile {
    @include rem(font-size, 40px);
  }
}

h3 {
  @include rem(font-size, 38px);

  @include mobile {
    @include rem(font-size, 28px);
  }
}

h4 {
  @include rem(font-size, 28px);

  @include mobile {
    @include rem(font-size, 23px);
  }
}

h5 {
  @include rem(font-size, 24px);

  @include mobile {
    @include rem(font-size, 20px);
  }
}

h6 {
  @include rem(font-size, 21px);

  @include mobile {
    @include rem(font-size, 19px);
  }
}

p,
a {
  font-weight: 400;
  line-height: 130%;
  @include rem(font-size, 18px);

  @include mobile {
    @include rem(font-size, 16px);
  }
}

small,
.text_small {
  @include rem(font-size, 12px);
}

.overflow-body {
  overflow: hidden !important;
}