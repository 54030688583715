@import "../../../assets/main.scss";

.section-1-about {
  background-color: $darkgrey;

  .section-1-wrapper-about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }

    .section-1-text-about {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 2rem;

      @include mobile {
        padding: 4rem 0rem;
        text-align: start;
      }

      p {
        color: $heading;
        font-weight: 500;
        font-size: 1rem;
        width: 90%;
        line-height: 1.6rem;
      }
    }

    .section-1-image-about {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;

      .text-image {
        position: absolute;
        left: 0;
        right: 0;

        h3 {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          // text-align: center;
          color: white;
        }
      }
      img {
        width: 100%;
        height: 50rem;
        object-fit: cover;

        @include mobile {
          width: 100%;
          height: 23rem;
        }
      }
    }
  }
}
