@import "../../../assets/main.scss";

.hero-banner {
  width: 100%;
  height: 100vh;
  background-image: url(../../../static/opatija-6.webp);
  box-shadow: 3px -1px 200px 120px rgb(0, 0, 0) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mobile {
    width: 100%;
    height: 80vh;
    box-shadow: 3px -1px 179px 121px rgba(0, 0, 0, 0.911) inset;
    object-position: center;
    background-position: center;
  }

  @include tablet {
    width: 100%;
    height: 70vh;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .text-hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      // padding-top: 20rem;
      // padding-bottom: 20rem;

      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
      }

      span {
        font-size: 0.8rem;
        color: white;
        font-weight: 600;
        padding-bottom: 1rem;
        letter-spacing: 0.1rem;

        @include mobile {
          font-size: 0.8rem;
        }
      }

      h1 {
        font-size: 4rem;
        letter-spacing: 0.2rem;
        color: white;
        font-weight: 600;
        text-transform: uppercase;

        @include mobile {
          font-size: 2rem;
        }
      }
    }
  }
}
