@import "../../../assets/main.scss";

.section-1-menu {
  background-color: $dark;

  .section-1-wrapper-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .section-1-text-menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 2rem;

      @include mobile {
        padding: 4rem 0rem;
      }

      h2 {
        color: white;
      }

      p {
        color: $heading;
        font-weight: 500;
        font-size: 1rem;
        width: 90%;
        line-height: 1.6rem;
      }
    }

    .section-1-image-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;

      .text-image {
        position: absolute;
        left: 0;
        right: 0;

        h3 {
          text-transform: uppercase;
          letter-spacing: 0.8rem;
          color: white;
          font-size: 1rem;
        }
      }

      img {
        width: 100%;
        height: 50rem;
        object-fit: cover;

        @include mobile {
          width: 100%;
          height: 23rem;
        }
      }
    }
  }
}
