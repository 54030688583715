@import "../../../assets/main.scss";

.info {
  background-color: $dark;
  padding: 0.6rem;
  position: relative;

  @include mobile {
    padding: 0;
  }

  &.active {
    display: none;
  }

  .info-wrapper {
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .info-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 1rem;
      color: #ffffff;

      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0.5rem;
      }

      .location,
      .mobile,
      .email {
        display: flex;
        align-items: center;

        letter-spacing: 0.03rem;

        span {
          color: #ffffff;
          font-size: 0.7rem;
          font-weight: 600;
          cursor: pointer;

          svg {
            font-size: 1.2rem;
          }
        }
      }

      .email {
        @include mobile {
          display: none;
        }
      }
    }

    .info-text-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 1rem;
      color: #ffffff;

      @include mobile {
        display: none;
      }

      span {
        color: #ffffff;
        font-size: 0.7rem;
        font-weight: 600;
        cursor: pointer;

        svg {
          font-size: 1.2rem;
        }

        &:hover {
          color: chocolate;
        }
      }

      .darkmode {
      }

      .location {
        display: flex;
        align-items: center;

        button {
          color: white;
          background: none;
        }
      }

      .mobile {
        display: flex;
        align-items: center;
      }

      .email {
        display: flex;
        align-items: center;
      }
    }
  }
}
