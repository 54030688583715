@import "../../../assets/main.scss";

.reservation {
  background-color: $darkgrey;

  .reservation__wrapper {
    // padding-top: 8rem;
    display: flex;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
    text-align: center;
    gap: 1rem;

    @include mobile {
      display: flex;
      flex-direction: column;
      padding-top: 2rem;
    }

    .reservation__item {
      width: 100%;
      height: 30rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.5rem;

      span {
        color: $heading;
        padding-bottom: 0.2rem;
        font-size: 0.9rem;
      }

      p {
        color: white;
        font-weight: 600;
        font-size: 2rem;
        padding-bottom: 1rem;
      }

      svg {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.9rem;
        color: white;
      }
    }

    .reservation__item__img {
      background-image: url(../../../static/inquire-photos.webp);
      box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      -webkit-box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      -moz-box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      background-position: center;
      width: 100%;
      height: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: $dark;
      padding-top: 17rem;
      cursor: pointer;

      &:hover {
        filter: none;
      }

      span {
        color: white;
        font-weight: 600;
        font-size: 1.6rem;
        padding-bottom: 1rem;
      }

      svg {
        font-size: 2rem;
      }

      p {
        color: #ffffff;
      }
    }

    .reservation__item__img2 {
      background-image: url(../../../static/delivered-food.webp);
      box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      -webkit-box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      -moz-box-shadow: 3px -1px 179px 121px rgba(4, 3, 3, 0.75) inset;
      background-position: center;
      width: 100%;
      height: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: $dark;
      padding-top: 17rem;
      cursor: pointer;

      &:hover {
        filter: none;
      }

      span {
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 0.9rem;
      }

      svg {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.5rem;
        color: white;
        padding-bottom: 1rem;
      }

      p {
        color: #ffffff;

        font-weight: normal;
        font-size: 0.9rem;
      }
    }
  }
}
