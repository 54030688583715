//stylelint-disable
@use "sass:math";

////
/// Mixins File
/// @group _mixins.scss
/// @author Clirim Arifi
////

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/// Media Query mixin for mobile devices 0 < 768
///@author Clirim Arifi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include mobile {
///     font-size:12px;
///     }
/// }
@mixin mobile {
  @media only screen and (max-width: 768px) {
    @content;
  }
  @media only screen and (min-device-width: 360px) and (max-device-width: 600px) and (orientation: landscape) {
    @content;
  }
}
@mixin mobileLandscape {
  @media only screen and (min-device-width: 360px) and (max-device-width: 820px) and (orientation: landscape) {
    @content;
  }
}
/// Media Query mixin for small tablets 768 < 991
///@author Clirim Arifi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet {
///     font-size:12px;
///     }
/// }
@mixin tablet {
  @media only screen and (min-width: 769px) and (max-width: 981px) {
    @content;
  }
}

/// Media Query mixin for big tablets 991 < 1199
///@author Clirim Arifi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet-big {
///     font-size:12px;
///     }
/// }
@mixin tablet-big {
  @media only screen and (min-width: 981px) and (max-width: 1201px) {
    @content;
  }
}
/// Media Query mixin for desktop devices over 1200px  > 1200
///@author Clirim Arifi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include desktop {
///     font-size:42px;
///     }
/// }
@mixin desktop {
  @media only screen and (min-width: 1201px) {
    @content;
  }
}

@mixin desktop-minus {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

/*=====  End of MEDIA QUERIES  ======*/

/*==============================
=            MIXINS            =
==============================*/
/// Rem mixin to calculate rem based spacing from the base pixel of the html document
/// Which can be set inside the mixin itself
///@author Clirim Arifi
///@param {CSS Property} $property - any css property
///@param {Value} $values - property value in pixels ONLY
///@output Calculated style from px to rem together with px fallback
///@example
/// h1 {
///   @include rem('font-size',16px);
/// }
/// //It outputs this: h1{font-size,16px; font-size:1rem;}
@mixin rem($property, $values, $important: false) {
  // Create a couple of empty lists as output buffers.
  $base-font-size: 16px; // should be consistent with your html/body font-size
  $px-values: ();
  $rem-values: (); // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value==0 or $value==0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) ==number and not unitless($value) and (unit($value) ==px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: math.div($value, $base-font-size);
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value * $base-font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
  } // output the converted rules
  @if $important==true {
    // #{$property}: $px-values !important;
    #{$property}: $rem-values !important;
  } @else {
    // #{$property}: $px-values;
    #{$property}: $rem-values;
  }
}
$font-size: 16;

@function em($pixels, $context: $font-size) {
  @return #{math.div($pixels, $context)}em;
}

@mixin transitionEase {
  transition: all 0.5s ease;
}

@mixin transitionCubic {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

%imgCropped {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%imgContain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
