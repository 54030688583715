$fontsName: Poppins,
Helvetica,
Arial,
sans-serif;


$black: #000;
$white: #fff;
$dark: #272727;
$darkgrey: #080707;
$heading: #ffffff;