@import "./assets/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100&family=Poppins:ital,wght@0,100;0,200;0,400;1,100&display=swap");

.app.dark {
    background-color: white;
    color: rgb(156, 156, 156);

    .info {
        background-color: white;

        span {
            color: $black !important;
        }

        p {
            color: $black !important;
        }
    }

    .nav-wrap {
        background-color: transparent;

        &.active {
            background-color: white;
            box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
            .hamburger-react {
                color: $black !important;
            }
            .nav-items,
            .nav-items-heading {
                li,
                p {
                    color: $black !important;
                }
            }
        }

        .nav-mobile {
            .nav-wrap-mobile {
                .hamburger-react {
                    color: white;

                    &.open {
                        .hamburger-react {
                            color: $black;
                        }
                    }
                }
                .nav-items-heading {
                    p {
                        color: white;
                    }

                    &.open {
                        p {
                            color: $black !important;
                        }
                    }
                }
            }
            .menu {
                background-color: white;

                .nav-items {
                    color: black;

                    li {
                        border-bottom: 0.01px solid $black;
                    }
                }

                .nav-items-icon {
                    span {
                        color: $black;

                        svg {
                            font-size: 1.7rem;
                        }
                    }
                }
            }
        }
    }

    .banner {
        background-color: white;

        .banner-item {
            span {
                color: $black !important;
            }
        }
    }

    .featured {
        background-color: white;

        .featured-wrapper {
            .featured-item {
                background-color: rgb(238, 235, 231);

                span,
                h2,
                button {
                    color: $black;
                }
            }
        }
    }

    .featured2 {
        background-color: white;

        .featured2-items {
            .featured2-text__items {
                span,
                h3 {
                    color: $black;
                }
            }
        }
    }

    .menu {
        background-color: white;

        .menu-wrapper {
            .menu__section-1 {
                h1 {
                    color: $black;
                }
                .vl {
                    border-left: 3px solid $black;
                }

                .menu__section-1__food {
                    span:nth-child(1) {
                        color: $black !important;
                    }
                }
            }
        }
    }

    .gallery {
        background-color: white;
    }

    .testimonial {
        background-color: whitesmoke;

        .mySwiper {
            .swiper {
                h2 {
                    color: $black;
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                color: black;
            }
        }
    }

    .reservation {
        background-color: white;

        .reservation__item {
            span,
            p {
                color: $black;
            }
        }
    }

    .footer {
        background-color: darkgray;

        .footer-wrapper {
            p,
            span {
                color: white;
            }
        }
        .footer-bottom {
            background-color: rgb(210, 227, 228);
        }
    }

    .section-1-about {
        background-color: whitesmoke;

        .section-1-text-about {
            p {
                color: $black;
            }
        }
    }
    .section-1-menu {
        background-color: whitesmoke;

        .section-1-text-menu {
            h2,
            p {
                color: $black;
            }
        }
    }

    .menu-section-2 {
        background-color: white;
        .menu__section-2 {
            .menu__section-2__text {
                h1,
                p {
                    color: $black;
                }
            }
            .vl {
                border-left: 3px solid $black;
            }

            .tabs {
                .item {
                    .vh {
                        border-bottom: 3px solid $black;
                    }
                    .tab {
                        color: gray;
                    }
                }
            }
            .menu__section-2__food {
                background-color: whitesmoke;
                .menu-items-wrapper,
                .menu-items-wrapper:nth-child(1),
                .menu-items-wrapper:nth-child(2),
                .menu-items-wrapper:nth-child(5),
                .menu-items-wrapper:nth-child(6) {
                    .text-items {
                        h2 {
                            color: $black;
                        }
                    }

                    .items {
                        .item {
                            span {
                                color: $black;
                                font-weight: 600;
                            }

                            span:nth-child(2) {
                                color: gray;
                                font-weight: normal;
                            }
                        }
                    }
                }

                .menu-items-lunch {
                    .menu-items-wrapper-lunch {
                        .text-items {
                            h2 {
                                color: $black;
                            }
                        }

                        .items {
                            .item {
                                span {
                                    color: $black;
                                    font-weight: 600;
                                }

                                span:nth-child(2) {
                                    color: gray;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }

                .menu-items-steak {
                    .text-items {
                        h2 {
                            color: $black;
                        }
                    }
                    .menu-items-wrapper-steak {
                        .items {
                            .item {
                                span {
                                    color: $black;
                                    font-weight: 600;
                                }

                                span:nth-child(2) {
                                    color: gray;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .section-3-menu {
        background-color: whitesmoke;

        .section-3-text-menu {
            h2,
            p {
                color: $black;
            }
        }
    }

    .menu-section-4 {
        background-color: white;
        .menu__section-4 {
            .menu__section-4__text {
                h1,
                p {
                    color: $black;
                }
            }
            .vl {
                border-left: 3px solid $black;
            }

            .tabs {
                .item {
                    .vh {
                        border-bottom: 3px solid $black;
                    }
                    .tab {
                        color: gray;
                    }
                }
            }
            .menu__section-4__drink {
                background-color: whitesmoke;

                .menu-items-wine {
                    .menu-items-wrapper:nth-child(1) {
                        .text-items {
                            h2,
                            p {
                                color: $black;
                            }
                        }

                        .items {
                            .item {
                                span {
                                    color: $black !important;

                                    span {
                                        color: gray;
                                        font-size: 0.8rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .menu-items-cocktails,
                .menu-items-wrapper-biere {
                    .text-items {
                        h2,
                        p {
                            color: $black;
                        }
                    }

                    .items {
                        .item {
                            span {
                                color: $black !important;

                                span {
                                    color: gray;
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .section-1 {
        background-color: whitesmoke;

        .section-1-text {
            h3,
            p {
                color: $black;
            }

            .hours,
            .reservation {
                p {
                    color: $black;
                }
            }
        }
    }

    .section-3 {
        background-color: whitesmoke;

        .section-3-text {
            h3,
            p {
                color: $black;
            }

            form {
                input,
                textarea {
                    color: $black;
                    &::content {
                        color: $black;
                    }
                }

                button {
                    border: 1px solid black;
                    background-color: $black;
                    color: whitesmoke;

                    &:hover {
                        color: black;
                        background-color: $white;
                    }
                }
            }
        }
    }

    .section-4 {
        background-color: whitesmoke;

        .item {
            h3,
            span {
                color: $black;
            }
        }
    }

    .section-2-booking {
        background-color: whitesmoke;

        .item-info-booking {
            span {
                color: $black;
            }
        }

        .item-booking {
            form {
                label {
                    color: $black;
                }

                input,
                textarea {
                    color: $black;
                }

                button {
                    border: 1px solid black;
                    background-color: $black;
                    color: whitesmoke;

                    &:hover {
                        color: black;
                        background-color: $white;
                    }
                }
            }
        }
    }
    .section-privacy-2 {
        background-color: whitesmoke;

        .wrapper {
            .item {
                h4 {
                    color: $black;
                }
            }
        }
    }
}
