@import "../../../assets/main.scss";

.banner {
  position: relative;
  height: 290.788px;
  background-color: #080707;
  padding: 5.3rem;

  @include mobile {
    padding: 1rem;
    height: auto;
  }

  .banner-wrapper {
    display: flex;
    justify-content: center;
    gap: 3rem;

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .banner-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      span {
        color: $white !important;
        font-weight: 500;
        font-size: 1.2rem;

        svg {
          font-size: 4rem;
        }
      }

      p {
        padding-top: 1rem;
        font-size: 1rem;
        color: gray;
        width: 80%;
        font-weight: 500;
      }
    }
  }
}
