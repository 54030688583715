@import "../../../assets/main.scss";

.testimonial {
  background-color: $dark;
  height: auto;
  width: 100%;

  @include mobile {
    height: auto;
  }

  .container {
    .mySwiper {
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;

      .swiper-button-next,
      .swiper-button-prev {
        color: black;
        font-weight: bolder;
        @include mobile {
          display: flex;
          padding: 0;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding-top: 11rem;
          width: 2rem;
          color: white;
        }
      }

      .swiper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 8rem 5rem;

        @include mobile {
          padding: 4rem 0rem;
          width: 100%;
          height: 100%;
        }

        h2 {
          color: #fff;
          padding-bottom: 2rem;
          font-size: 2.5rem;
          line-height: 3.6rem;

          @include mobile {
            font-size: 1.1rem;
            line-height: 2.5rem;
          }
        }

        p {
          color: gray;
          text-transform: uppercase;
          font-size: 1rem;
        }
      }
    }
  }
}
