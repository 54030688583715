@import "../../../assets/main.scss";

.menu-section-2 {
  background-color: $darkgrey;
  height: auto;

  @include mobile {
    padding: 0rem;
  }

  .menu-wrapper__section-2 {
    display: flex;
    flex-direction: column;
    gap: 10rem;
    padding-top: 10rem;
    padding-bottom: 10rem;

    .menu__section-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;

      .menu__section-2__text {
        h1 {
          font-size: 2.1rem;

          font-weight: 600;
          letter-spacing: 0.2rem;
          color: white;
        }

        p {
          padding-top: 1rem;
          font-size: 1rem;
          color: gray;
        }
      }

      .vl {
        padding-top: 1rem;
        border-left: 3px solid white;
        height: 2.5rem;
      }

      .tabs {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 4rem;
        }

        .vh {
          display: block;
          border-bottom: 3px solid white;

          width: auto;
        }

        .tab {
          font-size: 1rem;
          color: $heading;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .menu__section-2__food {
        background-color: $dark;
        width: 100%;

        .menu-items-dinner,
        .menu-items-1,
        .menu-items-2 {
          width: 100%;
          height: auto;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 3rem;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: start;
          padding: 2rem;

          @include mobile {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-content: center !important;

            align-items: center;
          }

          @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
        }

        .menu-items-wrapper:nth-child(1),
        .menu-items-wrapper:nth-child(6) {
          border: 1px solid rgb(88, 83, 83);
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          padding: 1rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                color: gray;
                font-weight: 500;
                font-size: 0.8rem;
              }
            }

            .item-price {
              display: flex;
              align-items: center;
              min-width: max-content;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-wrapper:nth-child(2),
        .menu-items-wrapper:nth-child(5) {
          display: flex;
          flex-direction: column;
          gap: 1.6rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              width: 70%;

              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.8rem;
              }
            }

            .item-price {
              display: flex;
              align-items: center;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-wrapper:nth-child(3),
        .menu-items-wrapper:nth-child(4) {
          display: flex;
          background-color: $darkgrey;
          flex-direction: column;
          gap: 1.6rem;
          padding: 1rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              width: 70%;

              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.8rem;
              }
            }

            .item-price {
              display: flex;
              align-items: center;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-wrapper-coffe {
          background-color: $darkgrey;
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          padding: 1rem;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
            gap: 0.3rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
            }

            .item {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              width: 70%;

              span {
                color: white;
                font-weight: 600;
              }

              span:nth-child(2) {
                color: gray;
                font-weight: normal;
                font-size: 0.8rem;
              }
            }

            .item-price {
              display: flex;
              align-items: center;

              span {
                color: gray;
                font-weight: 500;
              }
            }
          }
        }

        .menu-items-lunch {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          grid-gap: 3rem;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 2rem;

          .menu-items-wrapper-lunch {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 1.6rem;

            .text-items {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              gap: 0.3rem;

              h2 {
                font-size: 1.5rem;
                color: white;
                text-transform: uppercase;
                letter-spacing: 0.5rem;
              }

              p {
                color: gray;
                font-size: 0.8rem;
              }
            }

            .items {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              text-align: center;
              gap: 0.5rem;

              @include mobile {
              }

              .item {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;

                span {
                  color: white;
                  font-weight: 600;
                }

                span:nth-child(2) {
                  color: gray;
                  font-weight: normal;
                  font-size: 0.8rem;
                }
              }

              .item-price {
                display: flex;
                align-items: center;

                span {
                  color: gray;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .menu-items-steak {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 2rem;
          border-top: 1px solid white;

          .text-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 0.3rem;
            padding: 2rem 0rem;

            h2 {
              font-size: 1.5rem;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.5rem;
            }

            p {
              color: gray;
              font-size: 0.8rem;
            }
          }

          .menu-items-wrapper-steak {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 4rem;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 1rem;

            @include mobile {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              padding: 1rem;
            }

            @include tablet {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }

            .items {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              text-align: center;
              gap: 0.5rem;

              @include mobile {
              }

              .item {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;

                span {
                  color: white;
                  font-weight: 600;
                }

                span:nth-child(2) {
                  color: gray;
                  font-weight: normal;
                  font-size: 0.8rem;
                }
              }
              .item-price {
                span {
                  color: gray;
                  font-weight: 500;
                }
              }

              .item-price-wein {
                display: flex;
                align-items: center;
                gap: 1rem;

                span {
                  color: gray;
                  font-weight: 500;
                }
              }
              .item-price-collection {
                display: flex;
                align-items: center;
                gap: 1rem;

                span {
                  color: gray;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
