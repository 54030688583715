@import "../../../assets/main.scss";

.section-3-menu {
    background-color: $dark;

    .section-3-wrapper-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        @include mobile {
            display: flex;
            flex-direction: column;
        }

        .section-3-text-menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 2rem;

            @include mobile {
                padding: 4rem 0rem;
            }

            h2 {
                color: white;
            }

            p {
                color: $heading;
                font-weight: 500;
                font-size: 1rem;
                width: 90%;
                line-height: 1.6rem;
            }
        }

        .section-3-image-menu {

            width: 100%;
            height: 100%;




            img {
                width: 100%;
                height: 50rem;
                object-fit: cover;
                filter: brightness(0.4);

                @include mobile {
                    width: 100%;
                    height: 23rem;
                }
            }
        }
    }
}