@import "../../../assets/main.scss";

.footer {
  background-color: #1a191b;

  .footer-wrapper {
    padding: 3.5rem 1.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5rem;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column;
      padding: 2rem 0rem;
      gap: 2rem;
    }

    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }

    p {
      text-transform: uppercase;
      color: white;
      font-weight: 600;
    }

    span {
      color: rgb(184, 174, 174);
      line-height: 1.6rem;
      font-size: 0.9rem;
    }

    .welcome-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;
      width: 25%;

      @include mobile {
        width: 100%;
      }
    }

    .visit-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;

      span {
        display: flex;
      }
    }

    .reservation-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;
      width: 25%;

      @include mobile {
        width: 100%;
      }

      .btn-booking {
        background-color: transparent;
        padding: 0.9rem 0.3rem;
        border: 2px solid white;
        color: white;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        width: 100%;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }

    .about-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;
      width: 25%;

      @include mobile {
        width: 100%;
      }

      .privacy-policy {
        display: flex;
        flex-direction: column;
      }

      .icon {
        display: flex;
        align-items: center;
        text-align: center;
        gap: 1rem;
        color: white;
      }
    }
  }

  .footer-bottom {
    background-color: $dark;
    width: 100%;
    padding: 1rem;
    // height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column;
      height: auto;
      gap: 1rem;
      padding: 0.3rem;
    }

    .footer-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      color: white;

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 0rem;
        padding: 1rem 0rem;
      }

      p {
        color: white;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 0.1rem;

        @include mobile {
          font-size: 1.1rem;
        }
      }

      span {
        color: #fff;
        letter-spacing: 0.1rem;
        font-size: 0.6rem;
      }
    }
  }
}
