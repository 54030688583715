@import "../../../assets/main.scss";

.section-4 {
  background-color: $darkgrey;

  .section-4-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 2rem;
    padding: 10rem 10rem;

    @include mobile {
      display: flex;
      flex-direction: column;
      padding: 7rem 0rem;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1rem;

      span {
        color: white;

        svg {
          font-size: 4rem;
        }
      }

      h3 {
        color: $heading;
        font-size: 1rem;
        font-weight: normal;
      }

      p {
        color: gray;
      }
    }
  }
}
