@import "../../../assets/main.scss";
.gallery {
  background-color: $darkgrey;
  padding: 4rem 0rem;

  .gallery__box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @include mobile {
      display: flex;
      overflow-x: scroll;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
    }

    .gallery__images {
      width: 100%;
      height: 100%;

      @include mobile {
        min-width: 100%;
      }

      @include tablet {
        min-width: max-content;
      }

      .box-image {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
