@import "../../../assets/main.scss";

.section-1 {
  background-color: $darkgrey;

  .section-1-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }

    .section-1-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: 10rem;
      padding-bottom: 5rem;

      @include mobile {
        padding: 4rem 0rem;
      }

      h3 {
        color: #ffffff;
        font-weight: 600;
        font-size: 2.5rem;
      }

      p {
        color: $heading;
        font-weight: 600;
      }

      span {
        color: gray;
        line-height: 2rem;
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;

        @include mobile {
          display: flex;
          flex-direction: column;
        }

        button:nth-child(1) {
          padding: 0.7rem 3rem;
          background-color: white;
          color: black;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.15rem;
          cursor: pointer;
          border: none;
          font-size: 0.8rem;

          &:hover {
            background-color: yellowgreen;
            color: white;
          }
        }

        button:nth-child(2) {
          padding: 0.7rem 3rem;
          background-color: transparent;
          border: 1px solid white;
          color: $heading;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.15rem;
          cursor: pointer;

          &:hover {
            background-color: white;
            color: black;
          }
        }
      }

      .hours {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p {
          color: $heading;
          font-weight: 600;
        }
      }
      .reservation-contact {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }

    .section-1-image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 50rem;
        object-fit: cover;
        filter: brightness(45%);

        @include mobile {
          width: 100%;
          height: 23rem;
        }
      }
    }
  }
}
